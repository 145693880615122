.pan-tool {
    position: relative;

    .icon {
        width: 3.6rem;
        height: 3.6rem;

        display: block;
        position: absolute;
        top: 2.222rem;
        left: 2.77rem;
        cursor: pointer;

        &.active {
            background-image: url("assets/btn_pan_active.png");
            background-size: 3.6rem 3.6rem;
        }

        &.icon-pan {
            background-image: url("assets/btn_pan.png");
            background-size: 3.6rem 3.6rem;

            &:hover {
                background-image: url("assets/btn_pan_hover.png");
            }
        }
    }
}
