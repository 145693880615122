@import 'styles/variables.scss';

$font-color: #666666;

$search-box-height: 100%;
$search-bg-color: #fff;

.search-box-container {
  flex-grow: 1;
  // height: 100%;
  background-color: $root-background-color;
  display: flex;
  align-items: center;
}

.search-input-field {
  flex-grow: 1;
  margin-left: 2.5rem;
  display: flex;
  align-items: center;

  input {
    margin-left: 0.8rem;
    outline: none;
    text-transform: uppercase;
    font-size: 1.4rem;
    border: none;
    flex-grow: 1;
    height: 4rem;
  }

  .search-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background-image: url('assets/btn_search.png');
    background-size: 1.5rem 1.5rem;
  }
}
