.eyedropper-tool {
    position: relative;

    .icon {
        width: 3.6rem;
        height: 3.6rem;

        display: block;
        position: absolute;
        top: 2.222rem;
        left: 2.77rem;
        cursor: pointer;

        &.active {
            background-image: url("assets/btn_eyedropper_active.png");
            background-repeat: no-repeat;
            background-size: 3.6rem 3.6rem;
        }

        &.inactive {
            background-image: url("assets/btn_eyedropper_inactive.png");
            background-repeat: no-repeat;
            background-size: 3.6rem 3.6rem;
        }

        &.icon-eyedropper {
            background-image: url("assets/btn_eyedropper.png");
            background-repeat: no-repeat;
            background-size: 3.6rem 3.6rem;

            &:hover {
                background-image: url("assets/btn_eyedropper_hover.png");
                background-repeat: no-repeat;
                background-size: 3.6rem 3.6rem;
            }
        }
    }
}
