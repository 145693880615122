@font-face {
    font-family: 'averta_cyextrabold';
    src: url('avertacy-extrabold-webfont.eot');
    src: url('avertacy-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('avertacy-extrabold-webfont.woff2') format('woff2'),
         url('avertacy-extrabold-webfont.woff') format('woff'),
         url('avertacy-extrabold-webfont.ttf') format('truetype'),
         url('avertacy-extrabold-webfont.svg#averta_cyextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'averta_cybold';
    src: url('avertacy-bold-webfont.eot');
    src: url('avertacy-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('avertacy-bold-webfont.woff2') format('woff2'),
         url('avertacy-bold-webfont.woff') format('woff'),
         url('avertacy-bold-webfont.ttf') format('truetype'),
         url('avertacy-bold-webfont.svg#averta_cybold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'averta_cylight';
    src: url('avertacy-light-webfont.eot');
    src: url('avertacy-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('avertacy-light-webfont.woff2') format('woff2'),
         url('avertacy-light-webfont.woff') format('woff'),
         url('avertacy-light-webfont.ttf') format('truetype'),
         url('avertacy-light-webfont.svg#averta_cylight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'averta_cyregular';
    src: url('avertacy-regular-webfont.eot');
    src: url('avertacy-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('avertacy-regular-webfont.woff2') format('woff2'),
         url('avertacy-regular-webfont.woff') format('woff'),
         url('avertacy-regular-webfont.ttf') format('truetype'),
         url('avertacy-regular-webfont.svg#averta_cyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'averta_cysemibold';
    src: url('avertacy-semibold-webfont.eot');
    src: url('avertacy-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('avertacy-semibold-webfont.woff2') format('woff2'),
         url('avertacy-semibold-webfont.woff') format('woff'),
         url('avertacy-semibold-webfont.ttf') format('truetype'),
         url('avertacy-semibold-webfont.svg#averta_cysemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}