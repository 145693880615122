// @import "mixins.scss";

// $animation-easeOutExpo-speed: .4s;
// $animation-easeOutExpo-over: opacity;

div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 5;

  //   @include easeOutExpo;
}
