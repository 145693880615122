$app-name-height: 3.4rem;

.application-selection-item {
    padding: 1rem 0;
    display: inline-block;

    &,
    & > label {
        width: 100%;
    }

    label {
        font-size: 18px;
        height: $app-name-height;
    }

    .app-name-icon {
        display: inline-block;
        vertical-align: middle;

        height: $app-name-height;
        width: $app-name-height;
        line-height: $app-name-height;
        border-radius: 100%;
        color: #ffffff;

        .app-name-letter {
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            height: 100%;
            font-size: 13px;
        }

        &.app-name-checkbox-web {
            background-color: #00a7d7;
        }

        &.app-name-checkbox-inkspace {
            background-color: #f73393;
        }

        &.app-name-checkbox-bamboopaper {
            background-color: #f8cc13;
        }

        &.app-name-checkbox-montblanc {
            background-color: #202020;
            background-size: 90%;
            background-position: center;
            background-image: url("/assets/mb-logo.svg");
            background-repeat: no-repeat;
        }
    }

    .app-name-text {
        padding: 0 0 0 $app-name-height;
        display: inline-block;
    }
}
