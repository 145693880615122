.slider-wrapper {
    .slider {
        height: 100%;

        .thumb {
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 100%;
            border: 3px solid black;
            color: white;
            background-color: white;
        }

        .thumb:focus {
            outline: none;
        }

        .track {
            width: 100%;
        }
    }
}
