.footer-nav {
    float: left;
    line-height: 5rem;
    position: relative;

    ul {
        margin-left: 3.5rem;
    }

    li {
        cursor: pointer;
    }

    &:before {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        position: absolute;
        top: 1.8rem;
        left: 2rem;

        background-image: url("assets/img_wacom_logo.png");
        background-repeat: no-repeat;
        background-size: 1.5rem 1.5rem;
    }

    .inkNoteFilesStyle {
        background: #73ab50;
        border-radius: 1rem;
        padding: 0.2rem 1.5rem;
        color: white;
    }
}
