@import "styles/variables.scss";

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 96%;

  span.no-result {
    background-image: url("assets/img_no_result.png");
    background-size: 16.1rem 16.1rem;
    width: 16.1rem;
    height: 16.1rem;
  }

  p.no-result {
    font-size: 1.2rem;
    color: #666666;
  }

  p.no-result.bolder {
    font-weight: bold;
    text-transform: uppercase;
  }

  span.stream {
    background-image: url("assets/img_inkspace_stream.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 40.2rem;
    height: 21.9rem;
  }

  span.stream.bamboo {
    background-image: url("assets/img_bpaper@2x.png");
    width: 32.2rem;
    height: 30.9rem;
  }

  span.stream.montblanc {
    background-image: url("assets/img_montblanc_stream.png");
    width: 52rem;
    height: 31rem;
  }

  span.stream.wacomnotes {
    background-image: url("assets/img_wacom_notes_stream.svg");
    width: 52rem;
    height: 31rem;
  }

  span.stream.collaboration {
    background-image: url("assets/img_collaboration_stream.svg");
    width: 52rem;
    height: 31rem;
  }

  &.montblanc p.stream.heading {
    color: #b29f89 !important;
    margin-top: 0.4rem;
  }

  &.montblanc p.stream.body {
    font-weight: 600;
  }

  p.stream {
    color: #666666;
    font-size: 1.2rem;
  }

  p.stream.learn {
    text-transform: uppercase;
    margin-top: 0.5rem;

    a {
      color: inherit;
      text-decoration: none;
      :hover {
        color: $hoverColor;
      }
    }
  }

  p.stream.heading {
    font-size: 2.8rem;
    color: #00aeef;
    text-transform: uppercase;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    margin-top: 4.1rem;
  }

  p.stream.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1rem;
    margin-top: 3rem;
  }

  .stream.heading.group {
    color: #b9b9b9;
    text-transform: none;
  }

  span.montblanc_update_message {
    width: 60%;
    padding: 8rem 8rem;
    font-size: 12pt;
    line-height: 130%;
    font-weight: normal;
  }

  .montblanc_gold_color {
    color: #b29f89
  }
  
  a.montblanc_gold_color {
    text-decoration: underline;
  }
}
