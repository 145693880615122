@import "styles/variables.scss";
@import "styles/easing.scss";
@import "styles/reset.scss";

@import "./fonts/averta/avertacy.css";

:root {
  font-size: $root-font-size;
  color: $root-font-color;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  // width: 100vw;
  width: 100%;
  // height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  // overflow: hidden;
  // touch-action: none;
  font-size: 1.6rem;
  font-family: $root-font-style;

  background-color: $root-background-color;
  // position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  canvas {
    border: none;
  }
}

body.ink-editor {
  touch-action: none;
  position: fixed;
  -webkit-overflow-scrolling: touch;
}

button {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

a:hover {
  color: $hoverColor !important;
}

input[type="text"]::-ms-clear {
  display: none;
}

.flex-fill {
  flex: 1;
}

.flex {
  display: flex;
}

.pointer-cursor {
  cursor: pointer;
}

.Tipsy-inner {
  padding: 1rem;
}

.ps__rail-y {
  top: 0 !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  width: 6px !important;
}

.Toastify {
  .Toastify__toast-container {
    .Toastify__toast {
      min-width: 40rem;
      background: none;
      border: none;
      box-shadow: none;
      padding: 15px;

      div.Toastify__toast-body {
        text-align: center;
        font-size: 12pt;
        color: white;
        background: #333333;
        border-radius: 9px;
        padding: 15px;
        display: inline;
        box-shadow: 0px 0px 6px #333333;

        h1 {
          font-weight: 600;
        }
      }
    }

    /* style.css*/
    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .fadeIn {
      animation-name: fadeIn;
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    .fadeOut {
      animation-name: fadeOut;
    }

    /* Not needed with the cssTransition helper */

    .animate {
      animation-duration: 800ms;
    }
  }
}

// .Toastify__toast > div{
//   position: relative;
//   left: 0;
//   background: red
// }
.centered {
  width: 100%; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-in-transition-enter {
  opacity: 0;
}
.fade-in-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-in-transition-exit {
  opacity: 1;
}
.fade-in-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.slide-right,
.slide-left {
  color: red;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%) translateY(0%);
}
.slide-left-enter {
  opacity: 0;
  transform: translateX(100%) translateY(0%);
}
.slide-right-enter-active,
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%) translateY(0%);
}
.slide-right-exit,
.slide-left-exit,
.slide-up-exit,
.slide-down-exit {
  opacity: 1;
  transform: translateX(0%) translateY(0%);
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%) translateY(0%);
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%) translateY(0%);
}
.slide-right-enter-active,
.slide-right-exit-active,
.slide-left-enter-active,
.slide-left-exit-active,
.slide-down-enter-active,
.slide-down-exit-active,
.slide-up-enter-active,
.slide-up-exit-active {
  transition: opacity 200ms, transform 200ms;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(-100%) translateX(0%);
}
.slide-down-enter {
  opacity: 0;
  transform: translateY(100%) translateX(0%);
}

.slide-up-exit-active {
  opacity: 0;
  transform: translateY(100%) translateX(0%);
}
.slide-down-exit-active {
  opacity: 0;
  transform: translateY(-100%) translateX(0%);
}
