.profile-container {
    .generic-list {
        overflow: auto !important;
        margin: .8rem 0rem;

        .generic-list-item {
            padding: 0;
        }
    }
}

.profile-box {
    width: 4rem;

    .drop-down-icon-wrapper {
        position: relative;
        top: -0.8rem;
        right: -0.2rem;

        .drop-down-icon {
            background-image: url('./assets/profile_menu_toggle.png');
            width: 10px;
            height: 5px;
            float: right;

            &:after {
                content: "";
                overflow: hidden;
                clear: both;
            }
        }
    }
}

.profile-dropdown-head .generic-list {
    padding: 0 2rem 0rem 2rem;
    display: flex;
    flex-direction: column;
    //align-items: center;
}

.profile-image {
    border-radius: 50%;
}

.dropdown-content.bottom {
    top: 0;
    min-width: 26rem;
}

.profile-user-details {
    display: flex;
    padding: 20px 0 0 20px;
}

.full-name-wrap {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}

.generic-list-item div {
    display: flex;
    flex-direction: row;
}

.profile-dropdown-icon {
    width: 20px;
    height: 20px;
    margin: .6rem .6rem .6rem 0;
    background-position: center;
    background-repeat: no-repeat;
}

.profile-dropdown-text {
    margin-top: 1.15rem;
}

.user-profile-icon {
    background-image: url('./assets/my-account.svg')
}

.log-out-icon {
    background-image: url('./assets/sign-out.svg')
}

.user-data-download {
    background-image: url('./assets/data-download.svg')
}