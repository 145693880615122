$inactive-color: #d6d6d6;
$active-Color: #1b1b1b;

.page-indicator-wrapper {
    position: absolute;
    width: 12rem;
    height: 20%;
    right: 4rem;
    bottom: 8rem;
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 0.2rem;
    box-shadow: 0px 0px 20px;
    border: 1px solid rgb(204, 204, 204);

    .page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 0.2em;
        width: 60%;
        height: 100%;
        color: black;
        text-align: center;
    }

    .scroll-indicator {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .scroll-button {
            width: 100%;
            min-height: 30px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg.inactive path {
            fill: $inactive-color;
        }

        .track {
            height: 80%;
            width: 100%;

            .track-indicator {
                margin: 0 auto;
                width: 0.2rem;
                height: 100%;
                background-color: $inactive-color;

                .track-indicator-fill {
                    background-color: #1b1b1b;
                    transition: height 350ms ease-in-out;
                }

                .track-indicator-circle {
                    width: 1.6rem;
                    height: 1.6rem;
                    border-radius: 100%;
                    transform: translateX(calc(-50% + 1px));
                    border: 2px solid $active-Color;
                    background-color: white;
                }
            }
        }
    }

    .scroll {
        width: 0.8em;
        margin: 10px 0rem;
        background-color: $inactive-color;
        border-radius: 10px;
        overflow: hidden;

        transition: background-color 1s;
    }

    .scroll-fill {
        width: 0.8em;
        // border-radius: 10px 10px 0 0;
        border-radius: 10px;
        background-color: black;
    }
}
