div.no-groups {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3rem 4.5rem;
    line-height: 1.2rem;
    text-transform: none;
    color: #000;
    white-space: normal;

    p.head {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
    }

    p.body {
        font-size: 1.2rem;
        color: #808080;
    }
}