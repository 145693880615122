.contextmenu-wrapper {
    ul > li {
        &.exists-somewhere {
            &:after {
                width: 1.2rem;
                height: 0.9rem;
                display: block;
                position: absolute;
                right: 3rem;
                top: 1rem;

                content: "";
                background-image: url("./assets/img_check_grey.png");
                background-size: 1.2rem 0.9rem;
            }
        }

        &.exists-everywhere {
            &:after {
                width: 1.2rem;
                height: 0.9rem;
                display: block;
                position: absolute;
                right: 3rem;
                top: 1rem;

                content: "";
                background-image: url("./assets/img_check.png");
                background-size: 1.2rem 0.9rem;
            }
        }
    }
}
