@import "styles/variables.scss";


@mixin animation-hover-effect {

  @include easeOutExpo;
}

@mixin delete-item-modal {
  max-width: 39.3rem;

}

@mixin button-modal {
  button {
    width: auto;
    min-width: 8rem;
    height: 3.9rem;
    text-transform: uppercase;
    color: $button-font-color;
    background-color: $button-background-color;
    font-size: 1.2rem;
    border: none;
    padding: 0 1.5rem;
    margin: .1rem;

    &:first-child {
      margin-left: 0
    }

    &:hover {
      cursor: pointer;
    }
  }

  .secondary-button {
    background-color: transparent;
    font-weight: bold;
    color: black;
  }
}

@mixin perspective {
  -webkit-perspective: 160rem;
  -moz-perspective: 160rem;
  perspective: 160rem;
}

@mixin easeOutExpo($speed: $animation-easeOutExpo-speed,
  $over: $animation-easeOutExpo-over,
  $type: $animation-easeOutExpo-type) {
  -webkit-transition: $over $speed $type;
  -moz-transition: $over $speed $type;
  -o-transition: $over $speed $type;
  -ms-transition: $over $speed $type;
  transition: $over $speed $type;
}

@mixin modal-box-media {
  div.modal-box-overlay {
    &>.modal-box {

      .modal-box-footer {
        &>button {
          width: auto;
          min-width: 8rem;
        }
      }

      &.medium {
        width: 40rem !important;
      }

      .icon {
        right: 0;
      }
    }
  }
}