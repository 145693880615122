@import "styles/mixins.scss";

.zoom-wrapper {
    width: auto;
    position: fixed;
    bottom: 0;
    // left: 0;
    // top: auto;
    // display: flex;
    justify-content: center;
    text-align: center;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 1.2rem;

    .dropdown > div {
        width: 100%;
    }

    .dropdown-content.bottom {
        right: 0;
        min-width: 2rem;
    }

    .zoom-wrapper-children {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        margin-top: 2rem;

        .scale-factor {
            position: relative;
            cursor: pointer;
            width: 5rem;
            height: 3rem;
            line-height: 2.5rem;

            &:after {
                content: "";
                background-image: url("assets/btn_arrow_up.png");
                width: 0.6rem;
                height: 0.6rem;
                display: block;
                background-size: 0.6rem 0.6rem;
                position: absolute;
                top: 1rem;
                right: 0.2rem;
            }
        }
        & > .dropdown-arrow {
            margin: 0 0.5rem;
        }
    }

    ul.scaleFactor-list {
        width: 100%;
        // width: 10rem;
        // height: 22rem;
        // display: inline-block;
        padding: 0;
        position: absolute;
        bottom: -2rem;
        // right: 0;
        // left: -5px;
        // top: auto;

        background: #fff;

        -webkit-box-shadow: 0px -12px 12px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px -12px 12px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px -12px 12px 0px rgba(0, 0, 0, 0.12);

        @include easeOutExpo;
    }
}
