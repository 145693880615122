$root-font-style: Arial, sans-serif;
$root-font-size: 10px;
$root-background-color: #fff;
$root-documents-background-container-color: #f5f5f5;
$thumb-background: #fff;
$root-font-color: #333;
$root-border-style: #d9d9d9 solid;

$button-font-color: $root-background-color;
$button-background-color: #00a3e0;

$hoverColor: #0bf;

$page-header-height: 10rem;
$page-header-height-small: 5rem;

//See:  http://easings.net/#easeOutExpo
$animation-easeOutExpo-over: all;
$animation-easeOutExpo-speed: 0.6s;
$animation-easeOutExpo-type: cubic-bezier(0.19, 1, 0.22, 1);

$break-small: 368px;

//Video Page
$min-device-width: 560px;
$break-small-height: 560px;

$sidebar-width: 10rem;
$sidebar-width-small: 6rem;

$modal-box-overlay: rgba(19, 19, 19, 0.45);
$modal-box-padding: 3rem;
$animation-easeOutExpo-speed: 1.2s;
