.welcome-main {
    overflow: hidden;

    .welcome-wrapper {
        font-size: 40px;
        height: 100vh;
        display: grid;
        grid-template-columns: 0.6fr 1fr;
        grid-template-rows: minmax(1ch, 0.6fr);
        gap: 5rem;
        grid-gap: 5rem;
        align-content: center;
        padding: 0.6em;
        color: #1b1b1b;

        .text-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .heading {
            font-size: 22pt;
            line-height: 3rem;
            font-weight: 800;
        }

        .main-text {
            font-size: 16pt;
            line-height: 3rem;
            padding: 0.8rem 0;
        }

        .blue-button {
            cursor: pointer;
            padding: 2rem;
            background-color: #00b9f0;
            color: white;
            font-size: 16pt;
        }

        .blue-button:hover {
            background-color: #02a5d6;
        }

        .oval-image {
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .oval-image-0 {
            background-image: url("./assets/img_fte_01.svg");
        }
        .oval-image-1 {
            background-image: url("./assets/img_fte_02.svg");
        }
        .oval-image-2 {
            background-image: url("./assets/img_fte_03.svg");
        }
    }

    .progress {
        position: absolute;
        width: 100%;
        bottom: 2rem;
        background-color: transparent;
    }
}

@media screen and (min-width: 1200px) {
    .welcome-main {
        .welcome-wrapper {
            max-width: 1200px;
            margin: auto;
        }
    }
}

@media screen and (max-width: 560px) {
    .welcome-main {
        .welcome-wrapper {
            gap: 0.2rem;
            grid-gap: 0.2em;
            font-size: 35px;
            grid-template-columns: 1fr;
            grid-template-rows: 0.4fr 0.5fr;
        }
    }
}

.fade-enter {
    opacity: 0;
}
.fade-exit {
    opacity: 1;
}
.fade-enter-active {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
    transition: opacity 350ms;
}
