.img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    visibility: hidden;
    transform: scale(2.5);


    cursor: url("assets/cursor_pen.cur"), auto;
    cursor: url("assets/cursor_pen.png") 2.5 2.5, auto;
}

.visible {
    visibility: visible;
}
