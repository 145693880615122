@import "styles/mixins.scss";

.what-is-new-wrapper {
    position: absolute;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: $modal-box-overlay;
    display: flex;

    .what-is-new-content {
        min-width: 3em;
        max-width: 600px;
        // height: 30em;
        // max-width: 30em;
        // max-height: 30em;
        background-color: white;
        margin: auto;
        padding: 3em;
    }

    .cloud-icon {
        background-image: url("./assets/img_cloud_update.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 12em;
        margin: 1em 0;
    }

    .texts-wrapper {
        font-family: "Segoe UI";
        color: #333333;

        .header {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 2rem;
        }

        .main-text {
            font-size: 14px;
            line-height: 1.5;
            font-weight: 400;
            padding-bottom: 2rem;
        }

        @include button-modal;
    }
}
