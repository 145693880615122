div#ink-to-text-modal-container {
    width: 51.2rem;
    min-height: 44.8rem;
    padding-bottom: 0;

    .modal-box-body {
        padding-bottom: 0;
    }

    .dropdown-content {
        transform: translateX(-75%);
    }
}
