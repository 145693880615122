.semantic-details-wrapper {
    font-family: "averta_cyregular", sans-serif;

    .semantic-details {
        padding: 0.2em;
    }

    .text-abstract {
        line-height: normal;
    }

    a {
        color: #46c0f3;
    }

    .image-holder img {
        width: 100%;
    }

    .text-l {
        font-weight: 600;
        font-size: 14px;
        color: #1b1b1b;
        padding: 0.5em 0;
    }
}
