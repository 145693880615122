@import "styles/variables";

.sorter-head {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 3rem;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  font-size: 1.2rem;
  height: 5rem;

  .arrow-down {
    background-image: url('./assets/btn_arrow_down.png');
    background-size: .6rem .5rem;
    width: 0.6rem;
    height: .5rem;
    margin: 0 1rem;
  }

  &:hover {
    color: $hoverColor;
    .arrow-down {
      background-image: url('./assets/hover_btn_arrow_down.png');
    }
  }
}

.sorter-generic-list {
  padding-left:3rem;
  padding-right: 3rem;
}
