@import "styles/variables.scss";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

$document-view-width: 100%;
$document-view-height: calc(100vh - 100px);

.document-view {
    height: 100%;
    overflow: hidden;

    flex: 1;
    display: flex;

    .doScrollable {
        flex: 1;
        overflow-y: auto;
        background-color: $root-documents-background-container-color;

        .scrollable-content {
            display: grid;
            grid-auto-flow: row;
            grid-auto-rows: 1fr;
            grid-auto-columns: 1fr;
            grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
            grid-auto-rows: 36rem;
            grid-gap: 1rem;
            padding: 1rem;
        }

        -webkit-transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -moz-transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -o-transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -ms-transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);

        li {
            -webkit-transition: border 0.2s linear;
            -moz-transition: border 0.2s linear;
            -o-transition: border 0.2s linear;
            -ms-transition: border 0.2s linear;
            transition: border 0.2s linear;
            border: 0px solid #00a3e0;
            border-radius: 0;
        }

        .focused {
            border: 3px solid #00a3e0;
            border-radius: 5px;
        }

        .ps__rail-y {
            left: auto !important;
            right: 0 !important;
        }

        &:after {
            content: "";
            clear: both;
            display: block;
        }
    }
}
