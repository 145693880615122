@import "styles/mixins.scss";
@import "styles/variables.scss";

$animation-easeOutExpo-speed: .8s;

.tags-wrapper {
    width: 100%;
    height: 10rem;
    position: relative;
    display: inline-block;
    // z-index: 3;

    &.show-tags {
        z-index: 3;
        .tags-content.display-tags {
            display: inline-block;

            -webkit-box-shadow: 15px 0px 15px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 15px 0px 15px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 15px 0px 15px 0px rgba(0, 0, 0, 0.2);
        }

        ;

        .tags-header {
            border: none
        }

        -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);
    }

    // &.show-tags:after {
    //   // content: "";
    //   // width: 1.8rem;
    //   // height: 10rem;
    //   // background: #fff;
    //   // position: absolute;
    //   // top: 0rem;
    //   // left: 8rem;
    //   // z-index: 2;
    //   // opacity: .72;
    // }

    @media screen and (max-width: $break-small-height),
    screen and (max-height: $break-small-height) {
        &.show-tags:after {
            height: 6rem;
            left: 4.5rem;
        }
    }

    .tags-header {
        width: 100%;
        height: 100%;
        cursor: pointer;
        //position: relative;

        background-color: $root-background-color;
        border-top: .1rem $root-border-style;
        border-bottom: .1rem $root-border-style;
        // z-index: 2;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            font-size: 1.2rem;
            text-transform: uppercase;
            // z-index: 2;
        }

        &:hover {
            span {
                color: $hoverColor;
            }
        }

    }


    .icon {
        width: 2rem;
        height: 2rem;
        display: block;

        &.icon-tags {
            // z-index: 2;
            margin-bottom: 1rem;

            background-image: url('assets/tags-logo.png');
            background-size: 1.6rem 1.6rem;
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}