@import "styles/mixins.scss";

div#modal-delete-document{
  @include delete-item-modal;
}

.group-name{
  text-transform: uppercase;
  color: #333;
  font-size: 1.4rem;
  font-weight: bold;

  //&:before, &:after{
  //  content: "\"";
  //}
}

#btn-delete-document-cancel{
  background-color: white;
  color: #333;
  text-transform: none;
  font-weight: 600;
  font-size: 1.5rem;
}
