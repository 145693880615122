@import "styles/variables.scss";

// iOS Bouncing fix
.iOS-fix {
  position: fixed;
  overflow: hidden;
}

.fixedPosition {
  position: fixed;
}

.home {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .link {
    cursor: pointer;
  }

  div.main-content {
    height: 100%;
    background-color: $root-background-color;

    .flex-wrapper {
      display: flex;
      flex-flow: wrap;
      height: 100%;
    }

    .flex-row {
      flex-direction: row;
    }
    .flex-col {
      flex-direction: column;
    }

    .main-content-flex {
      display: flex;
      flex: 1;

      & > .page-sidebar {
        width: $sidebar-width;
        // z-index: 1;

        background-color: $root-background-color;
        border-right: 0.1rem $root-border-style;
      }

      & > .page-panel {
        display: flex;
        flex: 1 auto;
        flex-direction: column;
        background-color: $root-background-color;
      }

      .document-view .empty {
        background-color: white;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: $break-small-height), screen and (max-height: $break-small-height) {
    $sidebar-width: 6rem;
    $page-header-height: 5rem;

    .brand-container {
      width: $sidebar-width;
      height: $page-header-height;
    }

    div.main-content {
      .main-content-flex {
        & > .page-sidebar {
          width: $sidebar-width;
        }
      }

      .filter-container,
      .filter-result {
        min-width: 1px;
      }

      .footer-container {
        height: auto;

        .plus-button {
          margin-top: -1rem;
        }
      }
    }

    .document-view .empty span.stream {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 22rem;
      height: 16rem;
    }

    @media screen and (orientation: landscape) {
      .document-view .empty span.stream {
        height: 0;
      }
    }

    .page-panel .document-view .empty p {
      text-align: center;
      width: 80% !important;
      margin-top: 0 !important;
    }

    .footer-container .footer-nav ul {
      margin-left: 2.5rem;

      li {
        float: left;
      }
    }

    .footer-nav::before {
      left: 0.5rem;
    }

    // .brand-container {
    //   width: $sidebar-width;
    //   height: $page-header-height;
    // }

    .tags-wrapper {
      height: 6rem;
    }

    .folders-wrapper {
      padding: 0.6rem 0;

      .folder-box {
        height: $page-header-height;
      }
    }

    .search-box-container {
      width: 50%;

      .search-input-field {
        margin-left: 0.8rem;
      }
    }

    div#filter-result,
    .modal-box-overlay div.modal-box {
      position: initial;
      margin: initial;
      min-width: 1px !important;
    }
  }
}

.fade-in-transition-enter {
  opacity: 0;
}
.fade-in-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-in-transition-exit {
  opacity: 1;
}
.fade-in-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
