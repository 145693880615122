@import "styles/mixins.scss";

.hsl-picker {
    width: 30rem;
    height: 26rem;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: calc(100% - 1.5rem);
    z-index: 2;

    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    @include easeOutExpo;

    .hue-horizontal {
        height: 2rem !important;
        margin: 3rem;
        border-radius: 3rem;
        border: 0.1rem solid #e6e6e6;

        & > div > div {
            height: 1.5rem !important;
            width: 1.5rem !important;
            margin-top: 0.15rem !important;
            border-radius: 50% !important;
            border: 0.1rem solid #e6e6e6;
            cursor: pointer;
            &:before {
                content: "";
            }
        }
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

.hsl-mobile {
    div#hsl-h,
    div#hsl-s,
    div#hsl-l {
        div.slider {
            height: 2.2rem;

            span.slider__handle {
                width: 2rem;
                height: 2rem;
                top: 0.1rem;
            }
        }

        div.slider__track {
            height: 2.2rem;
        }
    }
}

div.color-tool {
    position: relative;

    .hsl-picker {
        position: relative;
    }

    .icon {
        transition: background-color 350ms;
        width: 3.6rem !important;
        height: 3.6rem !important;

        display: block;
        position: absolute;
        top: 2.222rem;
        left: 2.77rem;
        cursor: pointer;
        border-radius: 50%;

        &.active {
            background-image: url("assets/btn_color.png");
            background-size: 3.6rem 3.6rem;

            &:hover {
                background-image: url("assets/btn_color_white.png");
            }
        }

        &.icon-color {
            background-image: url("assets/btn_color_inactive.png");
            background-size: 3.6rem 3.6rem;
        }
    }
}
