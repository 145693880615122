$tag-bg-color: #00a3e0;
$tag-color: white;

.tag-selection-container {
    display: flex;
    flex: 1;
    height: 100%;
    font-size: 1.2rem;
}

.tag-selection-result,
.groupName {
    display: flex;
    flex: none;
    align-items: center;
    text-transform: uppercase;
}

.tag-selection {
    list-style: none;
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    padding: 1rem;
}

.tag-selection-item {
    border-radius: .7rem;
    padding: .6rem;
    margin-right: 1rem;
    background-color: $tag-bg-color;
    color: $tag-color;
    display: flex;
    width: auto;
    height: auto;
}

.tag-selection-item:hover {
    cursor: pointer;
}

.tag-selection-item::after {
    content: '\00d7';
    margin-left: 0.4em;
    margin-right: 0.1em;
}

.groupName {
    padding: 0 1rem;
    text-transform: uppercase;
    border-right: 0.1rem solid rgb(217, 217, 217);
}