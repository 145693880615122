.zoom-item {
    width: 100%;
    height: 4rem;
    padding-top: 2.5rem;
    color: #333;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
        color: #00a3e0;
    }
    &.active {
        color: #999;
    }
}
