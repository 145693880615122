@import "styles/mixins.scss";

// $modal-box-overlay: rgba(19, 19, 19, 0.45);
// $modal-box-padding: 3rem;
// $animation-easeOutExpo-speed: 1.2s;

$root-font-color: #666;

div.modal-box-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow: hidden;
    background-color: $modal-box-overlay;
    display: flex;
    justify-content: center;
    align-items: center;

    @include perspective;
    @include easeOutExpo;

    .modal-box {
        background-color: $root-background-color;
        padding: $modal-box-padding;

        position: initial;
        margin: initial;
        min-width: 1px !important;

        &.medium {
            width: 66%;
            min-height: 16rem;
            margin-top: -8rem;
        }

        //@include easeOutExpo;
        animation: moveIn $animation-easeOutExpo-speed / 2.5 ease-out;
        -webkit-animation: moveIn $animation-easeOutExpo-speed / 2.5 ease-out;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        position: absolute;
        top: 0;

        &.clear-button {
            background-image: url("./assets/btn_cancel.png");
            background-size: 1.5rem 1.5rem;
            right: 0;
            &:hover {
                cursor: pointer;
                background-image: url("./assets/hover_btn_cancel.png");
            }
        }
    }
}

.modal-box-header {
    position: relative;

    & > h1 {
        text-align: left;
        text-transform: uppercase;
        color: $root-font-color;
        font-size: 1.5rem;
        padding: 0 20px 0 0;
    }
}

.modal-box-body {
    font-size: 1.4rem;
    color: #333333;
    padding-top: 3rem;
    padding-bottom: 3rem;

    input,
    textarea {
        width: 100%;
        margin: 0;
        border: none;
        min-height: 4rem;
        font-size: 1.6rem;

        &.invalid-filed {
            animation: vibration 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            border: 0.1rem solid #c0392b;
            color: #c0392b;
        }
    }

    input[type="radio"] {
        min-height: 0;
    }
}

.modal-box-footer {
    @include button-modal;
}

@media only screen and (min-width: 600px) and (min-device-width: 600px) {
    @include modal-box-media;
}

@media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) and (min-width: 600px) and (min-device-width: 600px) {
    @include modal-box-media;
}

@keyframes vibration {
    10%,
    90% {
        transform: translate3d(-0.1rem, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(0.2rem, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-0.4rem, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(0.4rem, 0, 0);
    }
}

@keyframes moveIn {
    0% {
        opacity: 0;
        transform: scale(2);
        -webkit-transform: scale(2);
    }
    10% {
        opacity: 0.4;
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
    }
    60% {
        opacity: 0.9;
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1);
        -webkit-transform: scale(1);
    }
}

div.modal-box {
    input {
        background-color: #f2f2f2;
        padding-left: 1rem;
    }
}

//TODO; Move magic numbers to constants
@media only screen and (min-width: 600px) and (min-device-width: 600px) {
    div.modal-box {
        width: 45.2rem;

        & > input {
            width: 39rem;
        }
    }
}
