@import "styles/mixins.scss";

.canvas-type-menu-wrapper {
    position: relative;
    z-index: 5;

    @include easeOutExpo;

    & > div#context-menu {
        left: 50%;
        bottom: 1rem;
        margin-left: -10rem;
    }
}
