@import "styles/mixins.scss";
@import "styles/variables.scss";

.tags-content {
    min-width: 20.5rem;
    min-height: 10rem;
    // display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: #fff;
    // z-index: 2;
    -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);

    // .show-tags div.tags-header:after{
    //   background: #00A3E0;
    //   content: "";
    //   width: 1.8rem;
    //   height: 10rem;
    //   background: #fff;
    //   position: absolute;
    //   top: 0;
    //   left: -1.8rem;
    // }

    // @media screen and (max-width: $break-small-height),
    //      screen and (max-height: $break-small-height) {
    //       .show-tags .tags-header:after {
    //         height: 6rem;
    //       }
    //     }

    @include easeOutExpo;

    button.new-tag {
        width: 100%;
        text-align: left;
        background-color: #fff;
        border: 0;
        font-size: 1.3rem;
        padding-top: 0;
        padding-left: 3rem;
        height: 5rem;
        color: #333;
        text-transform: uppercase;

        &.empty {
            padding: 0;
        }

        &:hover {
            cursor: pointer;
            color: $hoverColor;
        }
    }

    ul {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 0;

        & > li {
            width: 100%;
            min-height: 4rem;
            padding: 1rem 6rem;
            white-space: nowrap;
            padding-left: 3rem !important;
            word-wrap: break-word;
            text-align: left;
            position: relative;

            &:hover {
                .btn-more {
                    display: inline-block;
                    top: 1.3rem;
                    right: 3rem;
                }
            }

            .tags-count {
                margin-left: 0.5rem;
                color: #42b1e4;
            }
        }

        .btn-more {
            width: 1.2rem;
            height: 1.2rem;
            display: none;
            position: absolute;
            top: 2.3rem;
            right: 2rem;

            background-image: url("assets/more.svg");
            background-size: 1.2rem 1.2rem;
        }

        & > li > a {
            color: #333;
            text-decoration: none;
            font-size: 1.4rem;

            &:hover {
                color: #0bf;
            }
        }
    }

    .context-menu-tags {
        min-width: 16.8rem;
        height: 10.3rem;
        border: none;

        & > ul {
            padding: 1rem;
            overflow: hidden;
        }

        .context-menu-item {
            width: 100%;
            min-height: 3rem;
            height: auto;
            font-size: 1.8rem;
            text-transform: initial;
            word-wrap: break-word;
            padding: 1rem 1rem 1rem 1rem !important;

            span {
                margin-right: 2rem;
            }

            &:first-child {
                padding-top: 2rem !important;
                margin-bottom: 1rem;
            }

            &:hover {
                color: #00a3e0;
                cursor: pointer;
            }
        }
    }

    div.wrapper-no-tags {
        height: 17rem;
        display: flex;
        flex-direction: column;
    }
}
