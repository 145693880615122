@import "styles/variables.scss";

.navigation-container {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;

    border-bottom: .1rem $root-border-style;
    background-color: $root-background-color;
}