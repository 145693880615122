@import "styles/variables";

[class*="ctr-panel-item"] {
    min-width: 8rem;
    height: 100%;
    display: inline-block;
    line-height: 7rem;
    text-align: center;

    & > .link {
        color: #333;
        text-decoration: none;
        font-size: 1.4rem;
    }

    &:hover {
        & > .link {
            color: $hoverColor;
        }
    }

    &.expandable:hover:after {
        background-image: url("assets/hover_btn_arrow_more.png");
    }

    &.expandable.inactive-item:hover:after {
        background-image: url("assets/btn_arrow_more.png");
    }

    &.expandable:after {
        content: "";
        display: inline-block;
        background-image: url("assets/btn_arrow_more.png");
        width: 0.6rem;
        height: 1.1rem;
        background-size: 0.6rem 1.1rem;
        cursor: pointer;
    }
}