@import "styles/variables.scss";

$preview-transition-duration: 0.3s;

#preview-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  overflow: hidden;

  button.close {
    width: 3em;
    height: 3em;
    top: 3%;
    right: 3%;
    position: absolute;
    z-index: 15;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    background-image: url("assets/btn_cancel.png");
    background-size: 1.3rem 1.3rem;
    cursor: pointer;
  }

  .preview-overlay {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color: rgb(19, 19, 19);
    justify-content: space-between;
    padding: 3rem;

    .preview-wrapper {
      width: 90%;
      height: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 0 0 0;

      .lazy-image-ref-wrapper {
        height: 100%;
        display: inline-block;
        border: none;
      }

      .multi-page-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: 0px;

        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      }

      .multi-page-wrapper {
        .lazy-load-image-background {
          height: unset;
        }
      }

      .lazy-load-image-background {
        width: 100%;
        height: 100%;
        align-self: center;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        background-color: white;
      }
    }

    .controls {
      display: flex;
      align-self: center;

      .title {
        align-self: center;
        font-size: 1.6rem;
        color: white;
      }

      .date {
        color: #999;
        font-size: 1.2rem;
        margin-top: 0.1rem;
        padding: 0 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .pn {
        width: 3rem;
        height: 6rem;
        background-size: 9px 16px;
        margin: 0 2rem;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
      }

      .pn.prev {
        align-self: flex-start;
        background-image: url("assets/btn_prev.png");
        &:hover {
          background-image: url("assets/hover_btn_prev.png");
        }
      }

      .pn.next {
        align-self: flex-end;
        background-image: url("assets/btn_next.png");
        &:hover {
          background-image: url("assets/hover_btn_next.png");
        }
      }
    }

    .icon.more-btn {
      width: 1.3rem;
      height: 1.3rem;
      display: inline-block;
      background-image: url("assets/btn_more.png");
      background-size: 2.8rem 0.8rem;
      width: 2.8rem;
      height: 0.8rem;
      align-self: center;
      margin-left: 1rem;
      cursor: pointer;

      &:hover {
        background-image: url("assets/hover_btn_more.png");
      }
    }
  }

  .inknote-holder {
    height: 100%;
    overflow: hidden;
    background-color: white;
  }

  @media screen and (max-width: $break-small-height), (max-height: $break-small-height) {
    .more-btn {
      transform: rotate(90deg);
    }
  }
}
