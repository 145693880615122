@import 'styles/variables';

.language-head {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    font-size: 1.2rem;
    height: 5rem;
    padding-right: 1rem;

    .arrow-up {
        background-image: url('./assets/btn_arrow_up.png');
        background-size: .6rem .5rem;
        width: .6rem;
        height: .5rem;
        margin: 0 1rem;
    }

    &:hover {
        color: $hoverColor;

        .arrow-up {
            background-image: url('./assets/hover_btn_arrow_up.png');
        }
    }

}

.language-generic-list {
    padding: 3rem 3rem 0 3rem;

    li:first-child {
        padding-top: 0;
    }

    li:last-of-type {
        //padding-bottom: 0;
    }
}

.language-generic-list-item {
    padding: 1.25rem 0 1.25rem 0;

    text-align: left;
    white-space: nowrap;
}