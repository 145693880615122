@import "styles/variables.scss";

$brand-bg-color: #00a3e0;
$brand-font-size: 2rem;
// $brand-width: $page-header-height;
// $brand-height: $page-header-height;

.brand-container {
    width: $page-header-height;
    height: $page-header-height;
    font-size: $brand-font-size;
    line-height: 8rem;
    background-color: $brand-bg-color;

    text-align: center;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ink-mode {
        width: 100%;
    }
}

.brand-logo {
    width: 100%;
    height: 100%;
    margin: 0.4rem;

    // background: url("assets/logo_text@4x.png");
    background-image: url("assets/brand-logo.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-size: 3.8rem 3.8rem;

}

/* ----------- iPad 1, 2, Mini and Air ----------- */

///* Landscape */
//@media only screen
//and (min-device-height: 700px)
//and (-webkit-min-device-pixel-ratio: 1) {
//  .brand-container.ink-mode{
//    width: $brand-width;
//  }
//
//  .brand-logo{
//    &.ink-mode {
//      width: 3.8rem;
//      height: 3.8rem;
//
//      background-image: url("assets/brand-logo.png");
//      background-repeat: no-repeat;
//      background-position: center center;
//      background-size: 3.8rem 3.8rem;
//    }
//  }
//}

/* ----------- Other devices  ----------- */
@media only screen and (max-height: 700px) {
    .brand-container.ink-mode .brand-logo {
        background-image: url("./assets/inkspace_logo_transparent_black.png");
        filter: invert(100%);
        background-size: 12.3rem 2.6rem;
    }
}

@media only screen and (min-height: 700px) {
    .brand-container {
        width: $page-header-height;
    }
}
