.semantic-sidebar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .semantic-type-control {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-basis: auto;
        justify-items: center;
        justify-content: center;

        border-bottom: 1px solid #dadada;
    }

    .sematic-entities-list-wrap {
        overflow-x: none;
        overflow-y: auto;
        flex: 1;
    }

    .language-dropdown {
        .dropdown,
        .dropdown-trigger,
        .language-head {
            width: 100%;
            display: inline-block;
            text-align: center;

            .arrow-up {
                display: inline-block;
            }
        }

        .dropdown-content {
            display: block;
            right: 1rem;
            left: 1rem;
        }
    }
}
