$border-style: #d9d9d9 solid;
$font-color: #333;

.folders-wrapper {
    .folder-box {
        height: 8.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .folder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.1rem;
        height: 4.1rem;
        border-radius: 50%;
        text-transform: uppercase;
        color: #fff;
        cursor: pointer;

        &.mate-stream {
            background-color: #f73691;
        }

        &.web-stream {
            background-color: #00a3e0;
        }

        &.montblanc-stream {
            background-color: #202020;
            background-size: 90%;
            background-position: center;
            background-image: url("../../../assets/mb-logo.svg");
            background-repeat: no-repeat;
        }

        &.wacom_notes-stream {
            background-size: 68%;
            background-position: center;
            background-image: url("../../../assets/logo_wacom_notes.svg");
            background-repeat: no-repeat;
            background-color: #06bbf0;
        }

        &.montblanc-stream.active {
            background-size: 70%;
        }

        &.bamboo_paper-stream {
            background-color: #f7cb19;
        }

        &.inknotefiles-stream {
            background-color: #73ab50;
        }

        &.collaboration-stream {
            background-size: 100%;
            background-position: center;
            background-image: url("../../../assets/collaboration-stream.svg");
            background-repeat: no-repeat;
        }

        &.active {
            width: 5.3rem;
            height: 5.3rem;
        }
    }
}