div.slider {
    input {
        width: 100%;
    }
}

.rc-slider-rail,
.rc-slider-track {
    background: none;
}

div#hue,
div#lightness,
div#saturation {
    width: 24rem;
    height: 1.8rem;
    margin: 2.4rem auto;
    position: relative;
    border: 0.1rem solid #e6e6e6;
    border-radius: 1rem;
}

div#hue {
    background-image: linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00);
}

div#lightness {
    background-image: linear-gradient(to right, #000, transparent, #fff);
}

div#saturation {
    background-image: linear-gradient(to right, #888, transparent);
}

.rc-slider-handle {
    z-index: 1;
    width: 1.4rem;
    height: 1.4rem;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    border: 0.1rem solid #bcbcbc;
}
