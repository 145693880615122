@import "styles/mixins.scss";

//$control-panel-width: 27.6rem;
$control-panel-height: 7.2rem;
$animation-easeOutExpo-speed: 0.4s;

.control-panel-wrapper {
    // display: block;
    height: $control-panel-height;
    position: fixed;
    bottom: 3rem;
    padding: 0 2rem;
    // left: calc(50% + #{$sidebar-width});
    // transform: translateX(calc(-50% - (#{$sidebar-width}/2)));
    z-index: 2;
    background-color: $root-background-color;
    border: 0.1rem $root-border-style;
    -webkit-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);

    .inactive-item span,
    .inactive-item:hover {
        color: rgba(0, 0, 0, 0.27);
        cursor: default;
    }

    @include easeOutExpo;

    #context-menu {
        bottom: 2.8rem;
    }

    & > ul {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
    }

    ul > li {
        &.exists-somewhere,
        &.exists-everywhere {
            &:after {
                right: 0;
            }
        }
    }
}

.panel-btn {
    &:after {
        content: "";
        clear: both;
        display: block;
    }
}
