.contextmenu-wrapper {
    ul {
        width: 100%;
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    ul>li {
        width: 100%;
        height: 3rem;
        color: black;
        line-height: .5rem;
        //text-transform: capitalize;
        cursor: pointer;
        text-align: left;
        padding: 1rem 3rem 0;
        white-space: nowrap;
        position: relative;
        //border: 1px solid red;

        &>.contextmenu-wrapper {
            min-width: 20.8rem;
            height: auto;
            display: none;
        }

        &:hover {
            color: #00a3e0;
        }
    }

    ul>li.children {
        position: relative;

        &:hover {
            &>.contextmenu-wrapper {
                margin-left: 85%;
                margin-top: -1.6rem;
                display: block;
            }
        }

        &:after {
            content: "";
            width: .6rem;
            height: 1.1rem;
            position: absolute;
            top: .8rem;
            right: 3rem;

            font-size: 1.5rem;
            background-image: url("assets/btn_arrow_more.png");
            background-size: .6rem 1.1rem;
            cursor: pointer;
        }

    }

}

.children-drop-down {
    &>ul {
        padding: 3rem !important;
    }

    &>ul li {
        text-align: left;
        padding: 0;
        text-transform: none;

        &:last-child {
            margin-bottom: 0
        }

        &>span {
            line-height: 3rem;
            padding-right: 2rem;
        }

        &.active {
            &:after {
                width: 1.1rem;
                height: 0.8rem;
                display: block;
                position: absolute;
                left: 100%;
                top: 1rem;

                content: "";
                background-image: url("assets/img_check.png");
                background-size: 1.1rem
            }

            font-style: italic;
            color: #999
        }

        &.partially-active {
            &:after {
                width: 1.1rem;
                height: 0.8rem;
                display: block;
                position: absolute;
                left: 100%;
                top: 1rem;

                content: "";
                background-image: url("assets/img_check_grey.png");
                background-size: 1.1rem
            }

            font-style: italic;
            color: #999
        }
    }
}