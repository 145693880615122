.dropdown {
    display: flex;
    position: relative;
}

.dropdown-trigger {
    display: flex;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 20;
    width: 100%;
    background: white;
}

.dropdown-content.top-overlap {
    padding-bottom: 1rem;
    bottom: -1rem;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .27);
}

.dropdown-content.bottom-overlap {
    padding-top: 1rem;
    top: -1rem;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .27);
    min-width: 18rem;
    width: auto;
    right: 1rem;
}

.dropdown-content.bottom {
    //padding-bottom: 1rem;
    top: 100%;
    width: 10rem;
    right: -.6rem;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, .27);
}

.dropdown-content.top-left-modal {
    bottom: -1rem;
    width: auto;
    min-width: 25.8rem;
    left: -3rem;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .27);
    padding-bottom: 1rem;
}

.dropdown-content.top-left {
    bottom: 0;
    min-width: 25.8rem;
    width: auto;
    right: 1rem;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .27);
}

@media only screen and (max-height: 600px) {
    .dropdown-content.top-left {
        overflow-y: auto;
    }

    .dropdown-content.top-left-modal {
        overflow-y: auto;
    }

    .language-generic-list {
        height: 90vh;
    }
}