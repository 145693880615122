@import "styles/variables.scss";

$checkbox-height: 2rem;

@media screen and (max-width: $break-small-height), screen and (max-height: $break-small-height) {
    $sidebar-width: 6rem;
    $page-header-height: 5rem;

    .brand-container {
        width: $sidebar-width;
        height: $page-header-height;
    }
}

.main-block {
    width: 100%;
    // height: calc(var(--vw, 1vw) * 100);
    height: 100vh;
    font-family: "averta", sans-serif;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;

    .page-header,
    .main-wrapper,
    .tab-header {
        background-color: #ffffff;
    }

    .main-wrapper {
        width: 80%;
        height: 100%;
        margin: auto;

        .progress-bar-wrapper {
            padding-top: $checkbox-height;
            color: #1b1b1b;
            font-size: 14px;
            transition: all 250ms ease-out;
            opacity: 0;

            .processing-progress {
                position: relative;

                .percentage {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .progress-bar-wrapper-visible {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 560px) {
        .main-wrapper {
            width: 100%;
        }
    }

    .tab-header {
        border: 1px solid #f0f0f0;
        border-width: 0px 1px 1px 0;

        ul {
            width: 80%;
            margin: auto;
            font-size: 16px;

            li {
                display: inline-block;
                padding: 0.8rem 1.5rem;
                margin: 0.8rem 0;
            }

            li:not(:first-child) {
                border-left: 2px solid #f0f0f0;
            }

            &:hover {
                cursor: pointer;
            }

            .tab-name-active {
                font-weight: bold;
            }
        }
    }

    .tab-description,
    .process-disclaimer {
        margin: 5rem 0;
        font-size: 18px;
    }

    .product-choice {
        .product-choice-head {
            margin: 5rem 0;

            .product-choice-head-text {
                font-size: 18px;
                font-weight: bold;
            }

            .product-choice-all label {
                font-size: 16px;
                margin-right: 2rem;
            }
        }
    }

    .content-block {
        padding: 0 3rem;

        input[type="checkbox"] {
            -webkit-appearance: none;
            width: $checkbox-height;
            height: $checkbox-height;
            border: 1px solid hsl(0, 0%, 85%);
            border-radius: 1px;
            vertical-align: sub;
            position: relative;
            transition: all 250ms ease-out;

            &:checked {
                background-color: #00baf0;
                border-color: #00b8f036;

                &:focus,
                &:hover {
                    border-color: #00b8f091;
                }
            }

            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
                background-size: 40px;
                background-repeat: no-repeat;
                background-position: center;
            }

            &:focus,
            &:hover {
                border-color: #00b8f091;
            }
        }
    }

    .content-block-available {
        .download-list-item {
            display: inline-block;
            padding: 2.6rem 0;
            font-size: 18px;
            border-top: 1px solid #d8d8d8;
        }

        .application-choices:last-child {
            border-bottom: 1px solid #d8d8d8;
        }

        .app-name-icon {
            margin-right: 3rem;
        }
    }

    .product-choice,
    .application-choices,
    .download-button-wrapper {
        position: relative;

        .product-choice-all,
        .application-selection-item input,
        .download-button-wrapper > button {
            position: absolute;
            right: 0;
        }

        .application-selection-item input {
            margin-top: 0.76rem;
        }
    }

    .blue-button {
        padding: 1rem 3rem;
        color: #ffffff;
        background-color: #00baf0;

        &:disabled {
            background-color: #b4ecfb;
        }
    }
}
