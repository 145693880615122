$menu-tool-border-style: 0.1rem solid #d9d9d9;

.menu-bar-wrapper {
    // width: 10rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    div[class$="-tool"] {
        width: 50%;
        height: 8rem;
        display: inline-block;
        float: left;
    }

    // .zoom-wrapper {
    //     width: 16rem !important;
    // }

    .ink-editor-sidebar {
        // width: 100%;
        height: calc(100% - 10rem);
        background-color: #fff;
        display: flex;
        flex-direction: column;

        .menu-tools {
            width: 100%;
            height: auto;
            &:first-child {
                border-bottom: $menu-tool-border-style;
            }

            &.menu-2 {
                //flex: 1;
                min-height: 8rem;
                position: relative;
                height: 64% !important;
            }
        }
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

///* Landscape */
//@media only screen
//and (min-device-height: 700px)
//and (-webkit-min-device-pixel-ratio: 1) {
//  .menu-bar-wrapper {
//    .hsl-picker {
//      left: calc(100% - 7rem);
//    }
//
//    .zoom-wrapper{
//      width: 10rem !important;
//    }
//    .menu-tools{
//      &:after{
//        content: "";
//        overflow: hidden;
//        clear: both;
//      }
//
//      [class $='-tool']{
//        width: 10rem;
//        height: 8rem;
//        flat: none;
//
//        &.zoom-tool{
//          position: absolute;
//          bottom: 0;
//          left: 0;
//          top: auto;
//        }
//      }
//    }
//  }
//}

/* ----------- Other devices  ----------- */
@media only screen and (min-height: 700px) {
    .menu-bar-wrapper {
        .hsl-picker {
            left: calc(100% - 7rem);
        }

        .zoom-wrapper {
            width: 10rem !important;
        }

        .menu-tools {
            &:after {
                content: "";
                overflow: hidden;
                clear: both;
            }

            [class$="-tool"] {
                width: 100%;
                height: 8rem;

                &.zoom-tool {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    top: auto;
                }
            }
        }
    }
}
