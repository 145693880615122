$context-menu-border-style: solid #d9d9d9;

.contextmenu-wrapper {
    position: fixed;
    min-width: 20.9rem;
    height: auto;
    background-color: #fff;
    border: .1rem $context-menu-border-style;

    z-index: 20;

    -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
}

.doc-ctrl-button {

    //position: relative;
    &>.contextmenu-wrapper {
        width: auto;
        height: auto;
        position: absolute;

        &>ul {
            padding: 2rem;
        }
    }
}

div#context-menu {
    ul>li {
        font-size: 1.4rem;
    }

    .inactive-item {
        color: rgba(0, 0, 0, 0.27);
        cursor: default;
    }
}