.convert-text-container {
    display: flex;
    flex-direction: column;
    textarea {
        width: 45.2rem;
        height: 28.9rem;
        background-color: #f2f2f2;
        border: none;
        outline: none;
        resize: none;
        font-size: 1.2rem;
        padding: 2rem;
    }
}

.convert-text-language-dropdown {
    width: 100%;
    margin-top: 1.8rem;
}
