.icon-chooser {
    display: flex;
    align-items: center;

    :last-child {
        padding-right: .6rem;
    }

    svg rect {
        fill: #9d9d9d
    }
}