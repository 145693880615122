.animated-node {
    opacity: 0;
}

.animated-node-enter {
    opacity: 0;
    display: block;
}

.animated-node-enter-active {
    opacity: .5;
    transition: opacity 100ms;
}

.animated-node-enter-done {
    opacity: 1;
}

.animated-node-exit {
    opacity: 1;
}

.animated-node-exit-active {
    opacity: .5;
    transition: opacity 100ms;
}

.animated-node-exit-done {
    opacity: 0;
    display: none;
}