@import "styles/variables";

//$root-font-color: #666;

li[class ^="footer-nav-item-"]{
  display: inline-block;
  margin-left: 1rem;

  a, a:link, a:visited,
  a:focus, a:active {
    text-transform: uppercase;
    color: $root-font-color;
    font-size: 1.2rem;
    text-decoration: none;
    cursor: pointer;
  }

  a.default-color{
    color: $root-font-color !important;
  }
  a:hover{
    color: $hoverColor;
  }
}
