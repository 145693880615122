@import "styles/mixins.scss";
@import "styles/variables.scss";

$doc-item-border-style: solid #d9d9d9;
$animation-easeOutExpo-over: box-shadow;

[id*="document-item-"] {
    @include easeOutExpo;

    &.active-document {
        z-index: 2;
        -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);

        &:hover {
            .document-header {
                visibility: hidden;
            }
        }
    }

    .document-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .document-header {
            width: 100%;
            height: auto;
            text-align: center;
            line-height: 5rem;
            position: relative;
            visibility: hidden;

            & > .cycle-box {
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 0.2rem;
                right: 1.2rem;
                left: auto;
                bottom: auto;
            }
        }

        .document-body {
            width: 100%;
            height: 86%;
            // max-width: 86%;

            .thumbnail-wrapper,
            .lazy-image-ref-wrapper,
            .offset,
            .offset-empty,
            .offset-balance,
            .offset-balance-empty,
            .thumbnail-wrapper img {
                transition: opacity 100ms, width 100ms, height 100ms;
            }

            .thumbnail-wrapper {
                margin: 0 0.8rem;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .lazy-load-image-background,
                .lazy-image-ref-wrapper {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    border: none;
                }

                & > span {
                    height: 100%;
                    width: 100%;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                }

                .bordered {
                    border: 0.1rem solid #d9d9d9;
                }

                .empty-doc {
                    display: block;
                    height: 86%;
                    width: 100%;
                    background-color: $thumb-background;
                    border: 0.1rem solid #d9d9d9;
                    background-image: url("./assets/empty-doc.svg");
                    background-size: contain;
                }

                .offset {
                    display: block;
                    background-color: white;
                    border: 0.1rem solid #d9d9d9;
                    height: 100%;
                    width: 100%;

                    span {
                        transform: translate(-0.3rem, 0.3rem);
                    }
                }

                .offset-empty {
                    display: block;
                    width: 100%;
                    height: 86%;
                }

                .offset-balance {
                    margin: 0 0 2rem 0;
                    height: 92%;
                    width: 100%;
                    // height: 100%;
                    // transition: all 250ms ease-in-out;
                }

                .offset-balance-empty {
                    width: 100%;
                    height: 86%;
                }

                img {
                    object-fit: contain;
                    background-color: white;
                    border: 0.1rem solid #d9d9d9;

                    // max-height: 100%;
                    // max-width: 100%;

                    // Firefox centering fix
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }

                img[src="#"] {
                    visibility: hidden;
                }
            }
        }

        .document-footer {
            min-height: 3rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .name {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: $root-font-color;
                display: inline-block;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-bottom: 0.2rem;
                margin-bottom: 0.2rem;
                white-space: nowrap;
                font-size: 1.4rem;
                text-overflow: ellipsis;
            }
        }

        .hit-area {
            display: inline-block;
            width: auto;
            height: 1.8rem;
            line-height: 0.7rem;
            cursor: pointer;
        }

        .icon {
            width: 1.3rem;
            height: 1.3rem;
            display: inline-block;

            &.share-btn {
                background-image: url("assets/img_shared.png");
                background-size: 1.4rem 1.4rem;
                width: 1.4rem;
                height: 1.4rem;
                margin-top: -0.5rem;
            }

            &.more-btn {
                background-image: url("assets/btn_more.png");
                background-size: 1.6rem 0.5rem;
                width: 1.6rem;
                height: 0.5rem;
                margin-top: -0.5rem;

                &:hover {
                    background-image: url("assets/hover_btn_more.png");
                }
            }

            &.approve-cycle {
                z-index: 2;
                background-image: url("assets/btn_unselect.png");
                background-size: 2.4rem 2.4rem;
                width: 2.4rem;
                height: 2.4rem;

                &:hover {
                    background-image: url("assets/hover_btn_unselect.png");
                }
            }

            &.approve-cycle-active {
                z-index: 2;
                background-image: url("assets/btn_select.png");
                background-size: 2.4rem 2.4rem;
                width: 2.4rem;
                height: 2.4rem;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        .document-header {
            visibility: visible;
        }

        -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    }

    &.active {
        .document-header {
            visibility: visible;
        }
    }
}

[data-overlay="overlay"] {
    background-color: #fff;
    opacity: 0.3;
}
