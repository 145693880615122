.filter-container {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 2.5rem;
    min-width: 31rem;

    .filter-result {
        font-size: 1.2rem;
        text-transform: uppercase;
        padding-right: 2.5rem;
        height: 100%;
    }
}