@import "styles/variables.scss";

//$border-style: #d9d9d9;
.footer-container {
  // z-index: 6;
  display: grid;
  grid-template-columns: 1fr 0.1fr;

  bottom: 0;
  border-top: 0.1rem $root-border-style;
  border-bottom: 0.1rem $root-border-style;
  background-color: $root-background-color;

  .footer-language {
    text-align: center;
    padding-left: 2rem;
    margin-right: 0.2rem;

    .dropdown {
      justify-content: flex-end;
    }
  }

  .canvas-type-menu.is-single-item .context-menu-item {
    text-align: center;
  }
}

@media only screen and (max-width: $min-device-width) {
  .footer-container .footer-nav ul li {
    line-height: 3rem !important;
    display: block;
  }

  .footer-nav:before {
    top: 0.8rem;
  }

  .footer-language {
    margin-top: -0.86rem;
  }
}
