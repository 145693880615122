.stream-name {
    height: 100%;
    display: flex;

    &.is-clickable {
        cursor: pointer;
    }

    span {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}