body .auth {
  font-family: "averta", sans-serif;
  font-weight: 400;
  margin: auto;
  padding-top: 0px;
  // width: 96vw;
  // height: 96vh;
  max-width: 600px;

  @media (min-width: 480px) {
    .auth {
      width: 80vw;
      // height: 80vh;
      padding: 12rem 0;
    }
  }

  .blueLink span a,
  .blueButton {
    cursor: pointer;
    color: #0098da;
    text-decoration: none;
    font-weight: 400;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
  }

  .blueButton:hover {
    color: #006bb4;
  }

  .auth-wrapper {
    padding: 2rem 1.2rem 0 1.2rem;
  }

  @media (min-width: 480px) {
    .auth-wrapper {
      padding: 70px 82px 63px;
    }
  }

  @media (min-width: 768px) {
    .auth-wrapper {
      border: 1px solid #ebebeb;
      border-radius: 10px;
    }

    & {
      padding-top: 10rem;
    }
  }

  @media (min-width: 1025px) {
    .auth-wrapper {
      padding-top: 70px;
      padding-right: 96px;
      padding-bottom: 57px;
      padding-left: 96px;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 38px;
    font-weight: 800;
    margin-bottom: 16px;
  }

  @media (min-width: 769px) {
    h1 {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 14px;
    }
  }

  .inputs-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    font-family: "averta", sans-serif;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .input-wrapper + .input-wrapper {
    margin-top: 47px;
  }

  @media (min-width: 769px) {
    .input-wrapper + .input-wrapper {
      margin-top: 50px;
    }
  }

  .input-wrapper:last-of-type {
    margin-bottom: 50px;
  }

  @media (min-width: 769px) {
    .input-wrapper:last-of-type {
      margin-bottom: 60px;
    }
  }

  .input-wrapper:last-of-type.change-margin {
    margin-top: 34px;
    margin-bottom: 44px;
  }

  @media (min-width: 769px) {
    .input-wrapper:last-of-type.change-margin {
      margin-bottom: 52px;
    }
  }

  label {
    display: block;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #999;
  }

  @media (min-width: 769px) {
    label {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 14px;
    }
  }

  input {
    display: block;
    width: 100%;
    padding: 0 0 11px;
    font-family: "averta", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #2b2b2b;
    outline: none;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    border-radius: 0;
    -webkit-appearance: none;
  }

  .form-wrapper {
    padding: 2.6rem 0;
  }

  form {
    padding-top: 1.2rem;
  }

  @media (min-width: 769px) {
    input {
      padding: 5px 0 10px;
      font-size: 16px;
    }
  }

  .logo {
    margin-bottom: 47px;
  }

  @media (min-width: 769px) {
    .logo {
      margin-bottom: 46px;
    }
  }

  .btn-default {
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    padding: 8px 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    border-radius: 8px;
    background-color: #0098da;
    -webkit-transition: background 0.3s linear;
    transition: background 0.3s linear;
    text-decoration: none;
  }

  .btn-default:hover {
    background-color: #006bb4;
    color: #fff !important;
  }

  @media (min-width: 769px) {
    .btn-default {
      padding: 12px 20px;
      font-size: 14px;
      line-height: 1.1em;
    }
  }

  .disable-btn {
    background-color: #b7b7b7;
    cursor: not-allowed;
  }

  .disable-btn:hover {
    background-color: #b7b7b7;
  }

  .no-account-box {
    margin-top: 2rem;
  }

  .no-account-message {
    padding: 0 8px 0 0;
  }

  .select-required {
    color: #444;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    border-style: solid;
    border-color: #b7b7b7;
    border-width: 0 0 1px 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../assets/arrow-down.png);
    background-position: 98% 50%;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .select-required::-ms-expand {
    display: none;
  }

  .select-required:hover {
    border-color: #888;
  }

  .select-required:focus {
    border-color: #aaa;
    // box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    // box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  .select-required option {
    font-weight: normal;
  }

  .img-wrapper {
    padding: 5rem 0;
  }

  .center {
    text-align: center;
  }

  .not-valid-item {
    color: #ff567e !important;
    border-color: #ff567e !important;
  }

  .not-valid-item:-webkit-autofill,
  .not-valid-item:-webkit-autofill:hover,
  .not-valid-item:-webkit-autofill:focus,
  .not-valid-item:-webkit-autofill:active {
    color: #ff567e !important;
    -webkit-text-fill-color: #ff567e !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }

  .password-sent .inner-button-wrapper {
    padding-top: 56px;
  }

  .spinner {
    position: fixed !important;
  }

  .force-show {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #e9e9e9;
    position: fixed;
    opacity: 0.5;
    z-index: 10;
    transition: opacity 1s ease-out;
  }

  .blur {
    filter: blur(0.5px);
  }

  .form-errors.fixed-list {
    width: 100%;
    padding: 17px;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1;
    text-align: center;
    color: #fff;
    list-style: none;
    background-color: #ff567e;
    text-align: center;
  }

  .checkbox-label {
    padding-left: 29px;
    position: relative;
    margin-bottom: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #2b2b2b;
    cursor: pointer;
  }

  @media (min-width: 769px) {
    .checkbox-label {
      padding-left: 29px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .checkbox-mark {
    width: 21px;
    height: 21px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #b7b7b7;
    border-radius: 2px;
  }

  .checkbox-mark::after {
    content: "";
    opacity: 0;
    width: 21px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../assets/checkmark.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 21px 16px;
    transition: opacity 0.3s;
  }

  input[type="checkbox"]:checked ~ .checkbox-mark::before {
    display: none;
  }

  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: relative;
    border-bottom: none !important;
    cursor: pointer;
    opacity: 0;
    transition: border 0.3s;
  }

  input[type="checkbox"]:checked ~ .checkbox-mark {
    border: none;
  }

  input[type="checkbox"]:checked ~ .checkbox-mark::after {
    opacity: 1;
  }

  .inputs-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .input-wrapper + .input-wrapper {
    margin-top: 47px;
  }

  .input-wrapper:last-of-type {
    margin-bottom: 30px;
  }

  @media (min-width: 769px) {
    .input-wrapper:last-of-type {
      margin-bottom: 50px;
    }
  }

  .input-wrapper.change-margin {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .input-wrapper:last-of-type.change-margin {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  @media (min-width: 769px) {
    .input-wrapper:last-of-type.change-margin {
      margin-bottom: 22px;
    }
  }
}

.auth-main-container {
  .footer-container {
    width: 100%;
    position: initial;
    border: none;
    padding-left: 0.8rem;
  }
}

/* RUSSIAN MESSAGE START */

.russia-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(19,19,19,.45);
  padding-bottom: 2rem;
}

.russia-message {
  text-align: center;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 3rem;
  padding: 3rem;
  z-index: 1555555;

  .russia-message-button-wrapper {
    padding-top: 2rem;
  }
}

/* RUSSIAN MESSAGE END */

.korea-disclaimer {
  border: 1px solid #b7b7b7;
  margin-top: -14px;
  margin-bottom: 24px;
  padding: 15px;
  overflow: auto;
  height: 80px;

  strong {
    font-weight: bold;
  }
  
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
}