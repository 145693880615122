@import "styles/mixins.scss";

.radio {
    display: flex;
    margin-top: 10px;
}
.radio:after {
    content: "";
    clear: both;
    overflow: hidden;
}
input[type="radio"] {
    opacity: 0;
    cursor: pointer;
    display: block;
    width: 0;
    height: 2rem;
    outline: none;
    z-index: 7;

    &:checked + label .radio-btn-cycle:before {
        background-color: #00a3e0;
    }
}

.radio-btn-cycle {
    height: 1.6rem;
    width: 1.6rem;
    display: block;
    float: left;
    content: "";
    background-color: white;
    border-radius: 50%;
    border: 0.2rem solid #9b9b9b;
    cursor: pointer;

    @include easeOutExpo;

    & + span {
        margin-left: 0.4rem;
        cursor: pointer;
        color: $root-font-color;
        text-align: left;

        &:hover {
            color: $hoverColor;
        }
    }

    &:before {
        content: "";
        height: 0.8rem;
        width: 0.8rem;
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        margin-top: -0.4rem;
        margin-left: -0.4rem;

        background: transparent;
        border-radius: 50%;

        @include easeOutExpo;
    }
}
