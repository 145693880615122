@import "styles/variables.scss";

$menu-tool-border-style: 0.1rem solid #d9d9d9;

.ink-editor-wrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ink-editor-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;

    canvas {
        &.tool-highlighter {
            cursor: url("assets/cursor_eraser_highlighter.cur"), auto;
            cursor: url("assets/cursor_eraser_highlighter.png") 8.5 8.5, auto;
        }
        &.tool-pen {
            cursor: url("assets/cursor_pen.cur"), auto;
            cursor: url("assets/cursor_pen.png") 2.5 2.5, auto;
        }
        &.tool-eraser {
            cursor: url("assets/cursor_eraser_highlighter.cur"), auto;
            cursor: url("assets/cursor_eraser_highlighter.png") 8.5 8.5, auto;
            &.disable {
                animation: cursor-animation 0.5s infinite;
            }
        }

        //&.tool-eyedropper{
        //  cursor: url('assets/cursor_pen.cur'), auto;
        //  cursor: url('assets/cursor_pen.png') 2.5 2.5, auto;
        //}
    }
}

@keyframes cursor-animation {
    10% {
        cursor: url("assets/loader/Comp 1_300.png") 14 14, auto;
        cursor: url("assets/loader/Comp 1_300.cur"), auto;
    }
    20% {
        cursor: url("assets/loader/Comp 1_301.cur"), auto;
        cursor: url("assets/loader/Comp 1_301.png") 14 14, auto;
    }
    30% {
        cursor: url("assets/loader/Comp 1_302.cur"), auto;
        cursor: url("assets/loader/Comp 1_302.png") 14 14, auto;
    }
    40% {
        cursor: url("assets/loader/Comp 1_303.cur"), auto;
        cursor: url("assets/loader/Comp 1_303.png") 14 14, auto;
    }
    50% {
        cursor: url("assets/loader/Comp 1_304.cur"), auto;
        cursor: url("assets/loader/Comp 1_304.png") 14 14, auto;
    }
    60% {
        cursor: url("assets/loader/Comp 1_305.cur"), auto;
        cursor: url("assets/loader/Comp 1_305.png") 14 14, auto;
    }
    70% {
        cursor: url("assets/loader/Comp 1_306.cur"), auto;
        cursor: url("assets/loader/Comp 1_306.png") 14 14, auto;
    }
    80% {
        cursor: url("assets/loader/Comp 1_307.cur"), auto;
        cursor: url("assets/loader/Comp 1_307.png") 14 14, auto;
    }
    90% {
        cursor: url("assets/loader/Comp 1_308.cur"), auto;
        cursor: url("assets/loader/Comp 1_308.png") 14 14, auto;
    }
    100% {
        cursor: url("assets/loader/Comp 1_309.cur"), auto;
        cursor: url("assets/loader/Comp 1_309.png") 14 14, auto;
    }
}
/** Legacy code */

/*  -webkit-overflow-scrolling: touch; */
.canvas-relative {
    width: calc(calc(var(--vw, 1vw) * 100) - 16rem);
    // height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    position: relative;
    left: 16rem;
    top: 0;
    overflow: hidden;
    background-color: #f5f5f5;
    color: #fff;
    //border-left: $menu-tool-border-style;
    border-left: 0.1rem solid #d9d9d9;
}

.ink-editor-wrapper {
    .menu-bar-wrapper,
    .brand-container,
    .ink-editor-sidebar,
    .menu-bar-wrapper .zoom-wrapper.ink-tool {
        width: 16rem;
    }
}

.canvas-absolute {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    transition: opacity 0.75s cubic-bezier(1, 0, 0.91, 0.62);

    #templateHolder {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    &.bp-doc {
        background-color: white;
        #templateHolder {
            background-size: contain;
        }
    }
}
.pointer {
    position: absolute;
    width: 20px;
    height: 20px;

    background-image: url("assets/cursor_pointer.svg");
}

.canvas-blocker {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    background: transparent;
}

.vector-canvas,
.raster-canvas {
    z-index: 2;
    // border: 1px solid red;
    position: relative;
}

#canvas-images,
#canvas-absolute {
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    & > img {
        position: relative;
        transform-origin: 0 0;
    }
}

#eye-dropper-overlay {
    z-index: 3;
    // display: none;
}
#eye-dropper-overlay:focus {
    outline: none;
    outline-width: 0;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

///* Landscape */
//@media only screen
//and (min-device-height:700px){
//  .canvas-relative{
//    left: 10rem;
//    width: calc(100vw - 10rem);
//  }
//}

/* ----------- Other tablet devices  ----------- */
@media only screen and (min-height: 700px) {
    .ink-editor-wrapper {
        .canvas-relative {
            left: 10rem;
            width: calc(calc(var(--vw, 1vw) * 100) - 10rem);
        }

        .menu-bar-wrapper,
        .brand-container,
        .ink-editor-sidebar,
        .zoom-wrapper.ink-tool {
            width: 10rem;
        }
    }
}
