.dot {
    width: .8rem;
    height: 0.8rem;
    border-radius: 1rem;
    background-color: #969696;
    margin: 0 0.5rem;
    transition: all 1s;
    display: inline-block;
}

.dot-current {
    background-color: #000000;
}

.progress-wrapper {
    margin: auto;
}
