@import "styles/mixins.scss";

div#export-modal-container {
  width: 27.6rem;

  .modal-box-footer {
    @include button-modal;
  }

  .export-items-container {
    display: flex;
    flex-direction: column;
  }
  .vertical {
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}
