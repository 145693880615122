@import "styles/variables.scss";

// $page-header-height: 10rem;
// $break-small: 376px;
// $log-padding: calc(368px - 4rem);

#header {
    display: flex;
    flex-direction: row;
    border-bottom: 0.1rem $root-border-style;
    height: $page-header-height;

    .search-box-container,
    .flex-spacer {
        display: flex;
        flex-grow: 1;
    }

    .profile-container {
        display: flex;
        margin-right: 3rem;
        align-items: center;
    }

    
    @media screen and (max-width: $break-small-height), (max-height: $break-small-height) {
        &.page-header {
            height: $page-header-height-small;
        }
    }
}
