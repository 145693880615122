.semantic-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;

    .menu-bar-wrapper {
        top: unset;
        left: unset;
        position: unset;

        .menu-tools:first-child {
            border: unset;
        }

        .zoom-wrapper {
            width: 10rem;
        }
    }

    .semantic-button {
        width: 5rem;
        height: 5rem;
        display: flex;
        margin: 3rem auto;
        align-self: center;
        align-items: center;
        text-align: center;
        cursor: pointer;

        svg {
            // align-self: center;
            flex: 1;
        }

        &.isActive {
            background-color: black;
            border-radius: 100%;

            svg path {
                fill: white;
            }
        }
    }

    .semantic-main-content {
        flex: 1 1 auto;
        overflow: hidden;

        .canvas-relative {
            display: flex;
            left: unset;
            top: unset;
            width: unset;

            .canvas-absolute {
                transition: all 250ms;
            }
        }
    }

    .sidebar-wrapper {
        transition: all 250ms;
        width: 20%;
        height: 100%;
        max-height: 100vh;
        border: 1px solid #dadada;
    }

    .semantic-element-overlay-active {
        border: 1px solid red;
    }

    #canvas-semantic-data {
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        z-index: 10;

        .semantic-element-overlay {
            transition: all 250ms;
            background-color: #69c9ec;
            opacity: 0.5;
            border-radius: 1rem;

            &:hover {
                opacity: 0.65;
            }
        }

        .semantic-element-overlay.active {
            border: 5px solid #69c9ec;
            opacity: 1;
            background-color: transparent;
        }
    }

    .sidebar-wrapper {
        .language-dropdown {
            border-top: 1px solid #dadada;

            .dropdown .dropdown-trigger .language-head {
                padding: 1.6em;
                text-align: start;
            }


            .language-generic-list {
                padding: 3rem 3rem 3rem 3rem;
            }

            .language-generic-list li:last-child {
                padding-bottom: 0;
            }
        }
    }
}
