.semantic-entity {
    border-style: solid;
    border-color: #dadada;
    border-width: 1px 0 0 0;
    padding: 1.2em;

    h1 {
        font-family: "averta_cysemibold", sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #1b1b1b;
        padding-bottom: 0.5em;
    }

    .info-row {
        margin-left: -8px;
        color: #8e8e8e;
        align-items: center;

        .icon-chooser svg {
            filter: opacity(60%);
        }
    }
}
