.semantic-icon-holder {
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
        filter: opacity(60%);
    }

    &.active {
        .stroke {
            stroke: #00b9f0;
        }

        .fill {
            stroke: unset;
            fill: #00b9f0;
        }
    }
}

.icon-gray {
    rect {
        stroke: #9d9d9d;
    }
}
