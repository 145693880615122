@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "styles/variables.scss";

$break-small: 368px;

#ink-to-video-main-container {
    display: table;
    width: 100vw;
    height: 100vh;

    .logo {
        min-width: 18rem;
        background-image: url("./assets/inkspace_logo_transparent.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center left;
        margin: 0 0 0 3rem;
        display: flex;
    }

    .logo:hover,
    #loginLink:hover {
        cursor: pointer;
    }

    .page-header {
        width: 100%;
        height: $page-header-height;
        justify-content: space-between;
        border-bottom: 0.1rem $root-border-style;
    }

    #loginLink {
        color: #0ab0ef;
        position: absolute;
        right: 2rem;
    }

    @media screen and (max-width: $break-small) {
        .logo {
            margin: 0 0 0 1rem;
        }

        #loginLink {
            // position: absolute;
            top: 2rem;
            right: 3rem;
        }

        .page-header {
            height: $page-header-height;
        }

        @media screen and (max-width: $break-small-height), (max-height: $break-small-height) {

            .logo {
                background-size: 12rem;
                height: 52px;
                margin-left: 2rem;
            }

            #loginLink {
                
                top: 2rem;
                
            }
        }
    }

    .content-container {
        display: table-row;
        height: 100%;
    }

    $primary-color: #333;

    #downloadLink {
        color: white;
        background-color: #00aeef;
        padding: 0.8em;
        border-radius: 0.4em;
        text-decoration: none;
    }

    #downloadLink:hover {
        color: white !important;
        cursor: pointer;
    }

    #download-component {
        height: 100%;
        font-family: "Roboto", Arial, Helvetica, sans-serif !important;
        color: $primary-color;
        //display: flex;
        align-items: center;
        justify-content: center;

        #download-content-wrapped {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            min-width: 80%;
            & > :nth-child(2) {
                font-family: "Roboto", Arial, Helvetica, sans-serif !important;
                font-variant: "Regular";
                font-size: 2rem;
                font-weight: 600;
                color: $primary-color;

                p {
                    padding-top: 1rem;
                }
            }
        }

        #downloadLink {
            text-transform: uppercase;
        }
    }

    .arrange-vertically > * {
        display: block;
        text-align: center;
        padding: 1.4rem;
    }

    #cameraIcon {
        height: 12rem;
        background-image: url("assets/camera.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        @media only screen and (max-width: $min-device-width) and (orientation: portrait) {
            height: 9rem;
        }

        @media only screen and (max-height: $min-device-width) and (orientation: landscape) {
            height: 7rem;
        }
    }

    #progress-bar {
        margin: auto;
        @media only screen and (min-width: 320px) {
            width: 80%;
        }
        @media only screen and (min-width: 768px) {
            width: 45%;
        }
        @media only screen and (min-width: 1224px) {
            width: 32%;
        }
    }
}
