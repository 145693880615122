@import "styles/variables.scss";

.plus-button-wrapper {
    display: flex;
    width: calc(100% - #{$sidebar-width});
    height: 0;
    position: absolute;
    justify-content: center;
  }
  
  .plus-button {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: none;
    background: white;
    -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.27);
    transform: translateY(-50%);
  }
  
  .plus-button-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: none;
    background: url('./assets/plus_btn.png') no-repeat;
    background-size: 1.4rem;
    background-position: 1.3rem;
    transform: rotate(45deg);
  }
  