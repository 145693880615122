.download-list-item {
    position: relative;

    .save-button-wrapper {
        position: absolute;
        right: 0;
        top: 2.5rem;

        button {
            background-color: #00baf0;
        }
    }
}
