.semantic-entities-list {
    overflow: auto;

    .accordion .card-header {
        background-color: unset;
        padding: unset;
        border: unset;
    }

    .accordion {
        & > .active-card-element {
            border: 2px solid #40baf2;
            transition: border-width 80ms;
        }
    }

    .card {
        border: unset;

        .card-body {
            background-color: #f6f6f6;
        }

        .card-header {
            cursor: pointer;
        }
    }
}
