@import "mixins.scss";

// Effect: 3D flip (Horizontal)
.flip-3d-x-appear {
  -webkit-transform: rotateY(-70deg);
  -moz-transform: rotateY(-70deg);
  -ms-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  opacity: 0;

  &.flip-3d-x-appear-active{
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 1;
  }
}


// Effect: 3D flip (Vertical)
.flip-3d-y-appear{
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transform: rotateX(-70deg);
  -moz-transform: rotateX(-70deg);
  -ms-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  opacity: 0;

  &.flip-3d-y-appear-active{
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

// Effect: Fall
.md-fall-appear{
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transform: translateZ(60rem) rotateX(20deg);
  -moz-transform: translateZ(60rem) rotateX(20deg);
  -ms-transform: translateZ(60rem) rotateX(20deg);
  transform: translateZ(60rem) rotateX(20deg);

  opacity: 0;

  &.md-fall-appear-active{
    -webkit-transform: translateZ(0px) rotateX(0deg);
    -moz-transform: translateZ(0px) rotateX(0deg);
    -ms-transform: translateZ(0px) rotateX(0deg);
    transform: translateZ(0px) rotateX(0deg);

    opacity: 1;
  }
}

// Effect: Error
.md-fall-1-appear{
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transform: translate(30%) translateZ(60rem) rotate(10deg);
  -moz-transform: translate(30%) translateZ(60rem) rotate(10deg);
  -ms-transform: translate(30%) translateZ(60rem) rotate(10deg);
  transform: translate(30%) translateZ(60rem) rotate(10deg);

  opacity: 0;

  &.md-fall-1-appear-active{
    -webkit-transform: translate(0) translateZ(0) rotate(0);
    -moz-transform: translate(0) translateZ(0) rotate(0);
    -ms-transform: translate(0) translateZ(0) rotate(0);
    transform: translate(0) translateZ(0) rotate(0);

    opacity: 1;
  }
}

// Effect: Fade in
.fade-in-appear{
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; /* add to fix webkit bug jitter */

    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);

    opacity: 0;

  &.fade-in-appear-active{
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);

    opacity: 1;
  }
}

// Effect: Slide-in (bottom-top)
.slide-in-bottom-appear {
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);

  opacity: 0;

  &.slide-in-bottom-appear-active{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    @include easeOutExpo(.8s);
  }
}

// Effect: Slide-in (top-bottom)
.slide-in-top-appear {
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
  opacity: 0;

  &.slide-in-top-appear-active{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    @include easeOutExpo(.8s);
  }
}


// Effect: Slide in (left to right)
.slide-in-ltr-appear{
    -webkit-transform: translateX(-20%);
    -moz-transform: translateX(-20%);
    -ms-transform: translateX(-20%);
    transform: translateX(-20%);

    opacity: 0;

  &.slide-in-ltr-appear-active{
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);

    opacity: 1;
  }
}

.fade-in-opacity-appear {
  opacity: 0;

  &.fade-in-opacity-appear-active {
    opacity: 1;
  }
}
// Effect: Slide in (right to left)
.slide-in-rtl-appear{
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;

  &.slide-in-rtl-appear-active{
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);

    opacity: 1;
  }
}
