@import "styles/variables";

.generic-list-item {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  white-space: nowrap;

  &.active {
    font-style: italic;
    color: #999;
  }

  &:hover {
    color: $hoverColor;
  }

  .spacer {
    width: 2.8rem;
  }

}
